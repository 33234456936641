import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Container, Spinner } from 'reactstrap';
import ClassList from '../components/ClassList';
import postedOn from '../utils/FormatDate';

const PostDetail = () => {
    const {postSlug} = useParams();
    const [post,
        setPost] = useState(null);
    const [isLoading,
        setIsLoading] = useState(true);
    const [error,
        setError] = useState(false);
    const API = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchPost = async() => {
            try {
                const response = await fetch(`${API}/post/${postSlug}`);
                if (response.ok) {
                    const data = await response.json();
                    setPost(data);
                } else {
                    setError(true);
                }
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        }
        fetchPost();
    }, [API, postSlug]);

    const wrapTables = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
      
        const tables = div.querySelectorAll('table');
        tables.forEach((table) => {
          const wrapper = document.createElement('div');
          wrapper.classList.add('table-responsive');
          table.parentNode.insertBefore(wrapper, table);
          wrapper.appendChild(table);
      
          const cells = table.querySelectorAll('td, th');
          cells.forEach((cell) => {
            cell.classList.add('table-bordered');
          });
        });
      
        return div.innerHTML;
      };

      if (isLoading) {
        return (
            <Container>
                <Spinner color="primary" style={{ width: '3rem', height: '3rem', display: 'block', margin: '2rem auto' }} />
            </Container>
        );
    }

    if (error) {
        return <Container><p>Error: {error.message}</p></Container>;
    }

    return (
        <Container>
            <ClassList />
            {post && (
                <Card className="px-2 px-lg-3 row gx-4 gx-lg-5">
                    <CardBody className='bg-light'>
                        <CardTitle className='h1 rounded border-bottom border-dark' style={{ height: '55px' }}>{post.title}</CardTitle>
                        <CardSubtitle className='h6 my-3'>
                            <p>By {post.author && (post.author.first_name + ' ' + post.author.last_name || post.author.username)}</p>
                            <p>
                                {post.created ? (
                                    <p>
                                        <small className="text-muted">
                                            {postedOn(post.created)}
                                        </small>
                                    </p>
                                ) : (
                                    <p>
                                        <small className="text-muted">Date not available</small>
                                    </p>
                                )}
                            </p>
                        </CardSubtitle>

                        <CardText>
                            <p dangerouslySetInnerHTML={{ __html: wrapTables(post.post) }}></p>
                        </CardText>

                    </CardBody>
                </Card>
            )}
        </Container>
    );
};

export default PostDetail;