import { formatDistanceToNow, isBefore, subDays } from "date-fns";

const postedOn = (date) => {
    const dateObj = new Date(date);
    const sevenDaysAgo = subDays(new Date(), 7);

    if (isBefore(dateObj, sevenDaysAgo)) {
        return `Posted on ${dateObj.toLocaleDateString()}`;
    } else {
        return `Posted ${formatDistanceToNow(dateObj)} ago`;
    }
};

export default postedOn;