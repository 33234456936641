import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, ListGroup, ListGroupItem } from 'reactstrap';
import classnames from 'classnames';

const About = () => {
  const [activeTab, setActiveTab] = useState('me');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

return (
    <div className="p-4">
        <h2 className="mb-4">About</h2>
        <Nav tabs className="block mb-4">
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === 'me' })}
                    onClick={() => { toggle('me'); }}
                    style={{ fontWeight: 'bold' }}
                >
                    Me
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === 'website' })}
                    onClick={() => { toggle('website'); }}
                    style={{ fontWeight: 'bold' }}
                >
                    Website
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId="me">
                <div className="mx-2">
                    <p className="lead">Dedicated Language Educator and Translator</p>
                    <ListGroup>
                        <ListGroupItem>Full Stack Developer with expertise in Python, JavaScript, Django, Node.js, and React.js</ListGroupItem>
                        <ListGroupItem>Passionate about empowering others to communicate and connect across cultures</ListGroupItem>
                        <ListGroupItem>Experienced English teacher with a proven track record of student success</ListGroupItem>
                        <ListGroupItem>Skilled translator specializing in legal, technical, and general translations</ListGroupItem>
                        <ListGroupItem>Committed to delivering accurate and culturally appropriate translations</ListGroupItem>
                        <ListGroupItem>Enthusiastic about exploring the nuances of language through subtitling projects</ListGroupItem>
                        <ListGroupItem>Eager to collaborate with individuals and organizations who share my passion for language and technology</ListGroupItem>
                    </ListGroup>
                </div>
            </TabPane>
            <TabPane tabId="website">
                <div className="">
                    <h3>Welcome to my English Learning Website!</h3>
                    <p>I'm excited to offer you a platform designed to help you improve your English skills and gain confidence in your language abilities.</p>
                    <p>Whether you're a beginner or an advanced learner, my website provides a variety of resources and interactive exercises to cater to your needs.</p>
                    <p>Explore my website to discover engaging lessons, practice activities, and opportunities to connect with other English learners.</p>
                    <p>I'm committed to creating a supportive and enjoyable learning environment where you can reach your English language goals.</p>
                    <h4>References</h4>
                    <ListGroup>
                        <ListGroupItem>
                            <strong>Title:</strong> English Grammar in Use
                        </ListGroupItem>
                    </ListGroup>
                </div>
            </TabPane>
        </TabContent>
    </div>
);
};

export default About;