import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess, userLoadedSuccess } from '../../redux/actions';
import axios from 'axios';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [re_password, setRePassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const API = process.env.REACT_APP_API_URL;

    const handleRegister = async () => {
        try {
            const response = await fetch(`${API}/auth/users/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password, re_password, email }),
            });

            if (response.ok) {
                setMessage('Registration successful');
                const loginResponse = await axios.post(`${API}/auth/token/login/`, { username, password });
                const { auth_token } = loginResponse.data;
                dispatch(loginSuccess(auth_token));

                // Load the user data after login
                const userResponse = await axios.get(`${API}/auth/users/me/`, {
                    headers: {
                        Authorization: `Token ${auth_token}`
                    }
                });
                dispatch(userLoadedSuccess(userResponse.data));
                navigate('/me/');
            } else {
                const data = await response.json();
                setMessage(data[Object.keys(data)[0]][0]); // Show the first error message
            }
        } catch (error) {
            console.error('An error occurred during registration', error);
            setMessage('An error occurred during registration');
        }
    };

    return (
        <div>
            <h2>Register</h2>
            {message && <p>{message}</p>}
            <Form>
                <FormGroup>
                    <Label for="username">Username:</Label>
                    <Input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="password">Password:</Label>
                    <Input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="re_password">Confirm Password:</Label>
                    <Input
                        id="re_password"
                        value={re_password}
                        onChange={(e) => setRePassword(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email:</Label>
                    <Input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormGroup>
                <Button color="primary" onClick={handleRegister}>
                    Register
                </Button>
            </Form>
        </div>
    );
};

export default Register;
