import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row } from 'reactstrap';

const UnitList = () => {
    const { classSlug } = useParams(); // Extract classSlug from URL params
    const [units, setUnits] = useState([]); // State to store units
    const [loading, setLoading] = useState(true); // State to track loading status
    const navigate = useNavigate(); // Correct usage of useNavigate
    const API = process.env.REACT_APP_API_URL; // API URL from environment variables

    // Fetch units when the component mounts or when classSlug changes
    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await fetch(`${API}/class/${classSlug}/units`);
                if (!response.ok) {
                    throw new Error('Error fetching units');
                }
                const data = await response.json();
                setUnits(data || []); // Set units to fetched data or an empty array if null
            } catch (error) {
                console.error('Error fetching units:', error);
                setUnits([]); // Ensure units is an empty array on error
            } finally {
                setLoading(false); // Set loading to false when fetching is done
            }
        };

        fetchUnits();
    }, [classSlug, API]); // Re-run effect when classSlug or API changes


    // Handle unit selection and navigate to the posts page for the selected unit
    const handleUnitClick = (unitSlug) => {
        navigate(`/class/${classSlug}/unit/${unitSlug}/posts/`); // Navigate to the posts page
    };

    return (
        <Row>
            {loading ? (
                <p>Loading units...</p> // Show loading message while fetching
            ) : units.length > 0 ? (
                <div className="container-fluid mb-3">
                    <h3>Units</h3>
                    {units.map((unit) => (
                        <button
                            key={unit.id}
                            type="button"
                            className="btn btn-outline-success m-2 btn-lg"
                            onClick={() => handleUnitClick(unit.slug)} // Trigger navigate on click
                        >
                            {unit.name} {/* Render unit name */}
                        </button>
                    ))}
                </div>
            ) : (
                <p>No units available.</p> // Fallback message if no units are loaded
            )}
        </Row>
    );
};

export default UnitList;
