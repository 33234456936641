import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function FooterInfo() {
return (
        <footer className="bg-light border text-center mt-3" style={{ width: "100%", padding: "20px" }}>
                <div className="container">
                        <div className="row">
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <div style={{ 
                                                width: '100px', 
                                                height: '100px', 
                                                borderRadius: '50%', 
                                                backgroundColor: '#ccc',
                                                overflow: 'hidden' // Add this line to hide any overflow
                                        }}>
                                                {/* Image holder */}
                                                <img src="https://img.freepik.com/premium-psd/user-approved-icon-3d-render-premium-psd_471402-356.jpg" alt="Hassan EL BAHI" style={{width: "100%", height: "100%", objectFit: "cover"}}></img>
                                        </div>
                                </div>
                                <div className="col-md-5">
                                        <h5>Hassan EL BAHI</h5>
                                        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                                <li>Teacher of English at Qualifying High School</li>
                                                <li>Arabic-English translator, subtitler, and transcriber</li>
                                                <li>Fullstack developer</li>
                                                <li>Developer of this website</li>
                                        </ul>
                                </div>
                                <div className="col-md-5">
                                        <h5>Contact Information</h5>
                                        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                                                <li>Email: me_80_me@hotmail.com</li>
                                                <li>LinkedIn: linkedin.com</li>
                                                <li>
                                                        Social Media:
                                                        <a href="https://www.facebook.com/bahi12" style={{ margin: '0 10px', color: "green" }}>
                                                                <FontAwesomeIcon icon={faFacebook} />
                                                        </a>
                                                        <a href="https://www.instagram.com/hassan_el_bahi/" style={{ margin: '0 10px', color: "green" }}>
                                                                <FontAwesomeIcon icon={faInstagram} />
                                                        </a>
                                                        <a href="https://x.com/bahi12" style={{ margin: '0 10px', color: "green" }}>
                                                                <FontAwesomeIcon icon={faTwitter} />
                                                        </a>
                                                        <a href="https://www.youtube.com" style={{ margin: '0 10px', color: "green" }}>
                                                                <FontAwesomeIcon icon={faYoutube} />
                                                        </a>
                                                </li>
                                        </ul>
                                </div>
                        </div>
                </div>
        </footer>
);
}

export default FooterInfo;