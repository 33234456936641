import React, { useState, useEffect, useCallback } from 'react';
import quotes from './quotes.json'; // Ensure the path is correct
import { Container, Card, CardBody, CardTitle, CardText } from 'reactstrap'; // Assuming you are using reactstrap

const RandomQuote = () => {
  const [quote, setQuote] = useState({});

  const cardStyle = {
    backgroundImage: "url('/images/quotes_bg.jpg')", // Correct path to the image in the public directory
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
  };

  // Function to get a random quote
  const getRandomQuote = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    const selectedQuote = quotes[randomIndex];
    setQuote(selectedQuote);
  }, []);

  // Get a random quote on component mount
  useEffect(() => {
    getRandomQuote();
  }, [getRandomQuote]);

  return (
    <Container className="text-center my-2">
      <Card>
        <CardBody style={cardStyle}>
          <div className="bg-light rounded text-dark" style={{ '--bs-bg-opacity': 0.75 }}>
            <CardTitle>Random quotes:</CardTitle>
            <CardText>
              {quote['quoteText']} {/* Correct access of quoteText */}
              <p>- {quote['quoteAuthor']}</p>
              <button className='btn btn-outline-primary btn-sm' onClick={getRandomQuote}>Get new quote</button>
            </CardText>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default RandomQuote;