import React, { useState } from 'react';
import { Button, Card, Col, Row, CardBody, CardTitle, Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import UnitList from './UnitList';
import ClassList from '../components/ClassList';
import postedOn from '../utils/FormatDate';
import DataFetcher from './DataFetcher';

function PostList() {
    const { classSlug, unitSlug } = useParams();
    const [data, setData] = useState(null);
    const [selectedUnitSlug, setSelectedUnitSlug] = useState(null); // Add state for selected unit

    const handleDataFetched = (data) => {
        setData(data);
    };

    const handleUnitClick = (unitSlug) => {
        setSelectedUnitSlug(unitSlug);
    }

    const renderPosts = () => {
        if (!data || !data.posts) return <p>No posts found</p>;
        return (
            <Row>
                <ClassList />
                {classSlug && (<UnitList
                    onClick={handleUnitClick}
                    selectedUnitSlug={selectedUnitSlug}
                />)}
                {data.posts.length > 0 ? (
                    data.posts.map(post => {
                        return (
                            <Col key={post.slug} xs={12} md={6} lg={4} className="mb-4">
                                <Card className="shadow-sm">
                                    <CardBody>
                                        <Row>
                                            <Col xs={4} className="d-flex align-items-center">
                                                {post.skill && post.skill.image && (
                                                    <img 
                                                        src={post.skill.image} 
                                                        alt={post.skill.name} 
                                                        style={{ width: '100px', height: '100px', objectFit: 'contain', transform: 'rotate(-90deg)'}} 
                                                        className="rounded"
                                                    />
                                                )}
                                            </Col>
                                            <Col xs={8}>
                                                <CardTitle className="h5 font-weight-bold">{post.title}</CardTitle>
                                                {post.created && (
                                                    <p>
                                                        <small className="text-muted">
                                                            {postedOn(post.created)}
                                                        </small>
                                                    </p>
                                                )}
                                                <a href={`/post/${post.slug}`} className="text-primary text-decoration-none">Read More</a>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })
                ) : (
                    <p>No posts found</p>
                )}
            </Row>
        );
    };

    return (
        <Container>
            <DataFetcher 
                classSlug={classSlug} 
                unitSlug={unitSlug} 
                onDataFetched={handleDataFetched} 
            />
            {data && renderPosts()}
            <div className="d-flex justify-content-center my-4">
                {data && data.prevPage && <Button onClick={() => data.fetchPosts(data.prevPage)} className="m-3">Previous</Button>}
                {data && data.nextPage && <Button onClick={() => data.fetchPosts(data.nextPage)} className="m-3">Next</Button>}
            </div>
        </Container>
    );
}

export default PostList;
