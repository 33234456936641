import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Input, Button, ListGroup, ListGroupItem, Spinner, Alert } from 'reactstrap';

const DictionarySearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(''); // New state for error message
    const API = process.env.REACT_APP_API_URL;

    const handleSearch = async (e) => {
        e.preventDefault();
        setPage(1);
        setResults([]);
        setError(''); // Clear any previous error
        setLoading(true);
        await fetchResults(1, true);
        setLoading(false);
    };

    const fetchResults = async (page, isNewSearch = false) => {
        try {
            const response = await axios.get(`${API}/dictionary/?w=${query}&page=${page}`);
            const data = response.data;
            if (data.results.length > 0) {
                setResults((prevResults) => isNewSearch ? data.results : [...prevResults, ...data.results]);
                setHasMore(data.next !== null);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setError(error.response.data.message); // Set the error message from the response
            } else {
                console.error('Error fetching dictionary entries: ', error);
            }
        }
    };

    const highlightResult = (text, highlight) => {
        const highlightStr = String(highlight).trim();
        if (!highlightStr.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlightStr})`, 'gi');
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === highlightStr.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
            ) : (
                part
            )
        );
    };

    const loadMoreResults = async () => {
        if (hasMore) {
            const nextPage = page + 1;
            setPage(nextPage);
            setLoading(true);
            await fetchResults(nextPage);
            setLoading(false);
        }
    };

    return (
        <Container className="d-flex flex-column align-items-center mt-5">
            <Form inline="true" onSubmit={handleSearch} className="mb-4 d-flex">
                <Input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search for a word"
                    className="mr-2 flex-grow-1"
                    style={{ fontSize: '1.5rem', padding: '0.5rem' }}
                />
                <Button type="submit" color="primary" style={{ fontSize: '1rem', padding: '0.5rem 1rem' }}>Search</Button>
            </Form>
            {loading && <Spinner color="primary" style={{ fontSize: '2rem', margin: '1rem' }} />} {/* Loading spinner */}
            {error && <Alert color="danger">{error}</Alert>} {/* Error message */}
            {results.length > 0 && (
                <>
                    <ListGroup>
                        {results.map((entry) => (
                            <ListGroupItem key={entry.id} className="d-flex justify-content-between align-items-center">
                                <div className="word" style={{ flex: '1', fontWeight: 'bold' }}>
                                    {highlightResult(entry.word, query)} ({entry.pos})
                                </div>
                                <div className="meaning" style={{ flex: '2', paddingLeft: '1rem' }}>
                                    {highlightResult(entry.meaning, query)}
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    {hasMore && results.length > 0 && (
                        <Button onClick={loadMoreResults} color="secondary" style={{ fontSize: '1.5rem', padding: '0.5rem 1rem', marginTop: '1rem' }}>
                            Load more results
                        </Button>
                    )}
                </>
            )}
        </Container>
    );
};

export default DictionarySearch;