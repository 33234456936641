import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUser} from '../redux/actions';
import Login from './auth/Login';
import { Card, CardBody, CardTitle } from 'reactstrap';
// Profile.js

const Profile = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isLoggedIn = useSelector((state) => state.isLoggedIn);

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    if (!isLoggedIn) {
        return <div>Please log in to view your profile.
            <Login/></div>;

    }
    if (!user) {
        return <div>Loading...</div>;
    }


        return (
            <Card>
                <CardBody>
                    <CardTitle tag="h2">Profile</CardTitle>
                    <table>
                        <tbody>
                            <tr>
                                <td><strong>Username:</strong></td>
                                <td>{user.username}</td>
                            </tr>
                            <tr>
                                <td><strong>Email:</strong></td>
                                <td>{user.email}</td>
                            </tr>
                            <tr>
                                <td><strong>First Name:</strong></td>
                                <td>{user.first_name}</td>
                            </tr>
                            <tr>
                                <td><strong>Last Name:</strong></td>
                                <td>{user.last_name}</td>
                            </tr>
                            <tr>
                                <td><strong>Date of birth:</strong></td>
                                <td>{user.dob}</td>
                            </tr>
                            <tr>
                                <td><strong>Country:</strong></td>
                                <td>{user.country}</td>
                            </tr>
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        );
    };



export default Profile;
