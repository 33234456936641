import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Container, CardBody, CardTitle, Button, Spinner } from 'reactstrap';
import { absoluteUrl } from '../utils/absoluteUrl';
import Modal from 'react-modal';

const GalleryPhotos = () => {
    const { galleryId } = useParams();
    const [photos, setPhotos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [loading, setLoading] = useState(true);
    const API = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const response = await fetch(`${API}/gallery/${galleryId}/photos/`);
                if (!response.ok) {
                    throw new Error('Error fetching photos');
                }
                const data = await response.json();
                setPhotos(data);
            } catch (error) {
                console.error('Error fetching photos:', error);
            }
        };

        fetchPhotos();
    }, [API, galleryId]);

    const openModal = (index) => {
        setCurrentPhotoIndex(index);
        setModalIsOpen(true);
        setLoading(true);
    };

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
        setZoom(1);
    }, []);

    const nextPhoto = useCallback(() => {
        setCurrentPhotoIndex((currentPhotoIndex + 1) % photos.length);
        setLoading(true);
    }, [currentPhotoIndex, photos.length]);

    const prevPhoto = useCallback(() => {
        setCurrentPhotoIndex((currentPhotoIndex - 1 + photos.length) % photos.length);
        setLoading(true);
    }, [currentPhotoIndex, photos.length]);

    const zoomIn = () => {
        setZoom(zoom + 0.1);
    };

    const zoomOut = () => {
        setZoom(zoom - 0.1);
    };

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowRight') {
            nextPhoto();
        } else if (e.key === 'ArrowLeft') {
            prevPhoto();
        } else if (e.key === 'Escape') {
            closeModal();
        }
    }, [nextPhoto, prevPhoto, closeModal]);

    useEffect(() => {
        if (modalIsOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalIsOpen, handleKeyDown]);

    return (
        <Container>
            <Row className="m-3 justify-content-center text-center">
                {photos.map((photo, index) => (
                    <Card key={photo.id} xs={12} md={6} lg={4} className="my-1 shadow-sm col-md-4">
                        <img
                            src={absoluteUrl(photo.image)}
                            style={{ width: '100%', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
                            className="rounded"
                            alt={photo.caption}
                            onClick={() => openModal(index)}
                        />
                        <CardBody>
                            <CardTitle className="h5 font-weight-bold">{photo.caption}</CardTitle>
                        </CardBody>
                    </Card>
                ))}
            </Row>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Photo Modal"
                className="modal-content"
                overlayClassName="ReactModal__Overlay"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        overflow: 'hidden',
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '10px',
                        padding: '20px',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                }}
            >
                {photos.length > 0 && (
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        {loading && <Spinner color="primary" />}
                        <div className="modal-image-container" style={{ flex: '1 1 auto', overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                            <img
                                src={absoluteUrl(photos[currentPhotoIndex].image)}
                                className="modal-image"
                                style={{ transform: `scale(${zoom})`, transition: 'transform 0.3s ease-in-out', maxWidth: '100%', maxHeight: '100%' }}
                                alt={photos[currentPhotoIndex].caption}
                                onLoad={() => setLoading(false)}
                            />
                        </div>
                        <div className="modal-buttons" style={{ flex: '0 0 auto', marginTop: '10px', textAlign: 'center' }}>
                            <Button color="secondary" onClick={prevPhoto}>Previous</Button>
                            <Button color="secondary" onClick={nextPhoto}>Next</Button>
                            <Button color="secondary" onClick={zoomIn}>Zoom In</Button>
                            <Button color="secondary" onClick={zoomOut}>Zoom Out</Button>
                            <Button color="danger" onClick={closeModal}>Close</Button>
                        </div>
                    </div>
                )}
            </Modal>
        </Container>
    );
};

export default GalleryPhotos;