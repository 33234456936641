import React, { useEffect} from "react";

const Dashboard = () => {
    
    useEffect(() => {
        const API = process.env.REACT_APP_API_URL;
        window.location.href = `${API}/admin`; // Redirect to the backend API
    }, []);

    // loading spinner icon
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
    

};

export default Dashboard;