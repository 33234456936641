import React, { useEffect, useState } from 'react';
import { Card, Row, Container, Button, CardBody, CardTitle } from 'reactstrap';
import { absoluteUrl } from '../utils/absoluteUrl';

const GalleryList = () => {
    const [galleries, setGalleries] = useState([]);
    const API = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const response = await fetch(`${API}/gallery/galleries/`);
                if (!response.ok) {
                    throw new Error('Error fetching galleries!');
                }
                const data = await response.json();
                setGalleries(data);
            } catch (error) {
                console.error('Error fetching galleries:', error);
            }
        };

        fetchGalleries();
    }, [API]);

    const renderGalleries = () => {
        return (
            <Row className="m-3 justify-content-center text-center">
                {galleries.map(gallery => (
                    <Card key={gallery.id} xs={12} md={6} lg={4} className="my-1 shadow-sm col-md-4">
                        <a href={`/gallery/${gallery.id}/photos`}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {gallery.latest_photos && gallery.latest_photos.map(photo => (
                                    <img
                                        key={photo.id}
                                        src={absoluteUrl(photo.image)}
                                        style={{ width: '50%', height: '75px', objectFit: 'cover' }}
                                        className="rounded"
                                        alt={photo.caption}
                                    />
                                ))}
                            </div>
                        </a>
                        <CardBody>
                            <CardTitle className="h5 font-weight-bold">{gallery.name}</CardTitle>
                            <Button variant="primary" size="sm" href={`/gallery/${gallery.id}/photos`}>
                                View Photos
                            </Button>
                        </CardBody>
                    </Card>
                ))}
            </Row>
        );
    };

    return (
        <Container>
            {renderGalleries()}
        </Container>
    );
};

export default GalleryList;