import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

const DataFetcher = ({ classSlug, unitSlug, onDataFetched }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState({});
    const API = process.env.REACT_APP_API_URL;

    const buildPostsUrl = useCallback((classSlug, unitSlug) => {
        let url = `${API}/`;
        if (classSlug && unitSlug) {
            url = `${API}/class/${classSlug}/unit/${unitSlug}/posts/`;
        } else if (classSlug) {
            url = `${API}/class/${classSlug}/posts/`;
        }
        return url;    
    }, [API]);

    const fetchPosts = useCallback(async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Error fetching posts');
            }
            const data = await response.json();
            const posts = Array.isArray(data) ? data : data.results;
            const nextPage = data.next;
            const prevPage = data.previous;
            setData(prevData => ({ ...prevData, posts, nextPage, prevPage }));
        } catch (error) {
            setError(error);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [classesResponse, unitsResponse, postsResponse] = await Promise.all([
                    fetch(`${API}/class/`),
                    classSlug ? fetch(`${API}/class/${classSlug}/units/`) : Promise.resolve(null),
                    fetch(buildPostsUrl(classSlug, unitSlug)),
                ]);

                if (!classesResponse.ok) throw new Error('Error fetching classes');
                const classes = await classesResponse.json();

                let units = [];
                if (unitsResponse && unitsResponse.ok) {
                    units = await unitsResponse.json();
                }

                const postsData = await postsResponse.json();
                const posts = Array.isArray(postsData) ? postsData : postsData.results;
                const nextPage = postsData.next;
                const prevPage = postsData.previous;

                setData({ classes, units, posts, nextPage, prevPage });
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [API, classSlug, unitSlug, buildPostsUrl]);

    if (isLoading) {
        return <Spinner color="primary" style={{ width: '3rem', height: '3rem', display: 'block', margin: '2rem auto' }} />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    onDataFetched({ ...data, fetchPosts });
    return null;
};

export default DataFetcher;